import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

import { MdLocationOn, MdContacts, MdTimer } from 'react-icons/md'
import GoogleMapReact from 'google-map-react'

const ZeewoldePage = ({ data }) => (
  <Layout>
    <SEO
      title="Zeewolde"
      keywords={[
        `Logopediepraktijk`,
        `Mireille Peters`,
        `Zeewolde`,
        `Harderwijk`,
        `Ermelo`,
      ]}
    />
    <div className="location-detail">
      <h2 className="location__details text-center py-5">
        {data.locaties.edges[0].node.locatie}
      </h2>
      <div className="container">
        <div className="row">
          {data.locaties.edges[0].node.fotos
            ? data.locaties.edges[0].node.fotos.map(foto => (
                <div key={foto.id} className="photos col-sm-12 col-md-4">
                  <div className="location__photos">
                    <Img
                      fixed={foto.fixed}
                      alt={data.locaties.edges[0].node.locatie}
                      className="location__photo"
                    />
                  </div>
                </div>
              ))
            : undefined}
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <div className="adress">
              <div className="icon__wrapper">
                <MdLocationOn className="icon icon--location-detail" />
                <h5>Adres</h5>
              </div>
              {data.locaties.edges[0].node.adres} <br />
              {data.locaties.edges[0].node.postcode} <br />
              {data.locaties.edges[0].node.plaats}
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="adress">
              <div className="icon__wrapper">
                <MdContacts className="icon icon--location-detail" />
                <h5>Contactgegevens</h5>
              </div>
              {data.locaties.edges[0].node.telefoonnummer} <br />
              {data.locaties.edges[0].node.email}
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="adress">
              <div className="icon__wrapper">
                <MdTimer className="icon icon--location-detail" />
                <h5>Openingstijden</h5>
              </div>
              {data.locaties.edges[0].node.openingsDagen} <br />
              {data.locaties.edges[0].node.openingstijden}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="maps">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyBKwFKdYFFM6xBhLn5fl8yyQiZHwGjkLHs',
                }}
                defaultCenter={{
                  lat: data.locaties.edges[0].node.googleMapsLocatie.lat,
                  lng: data.locaties.edges[0].node.googleMapsLocatie.lon,
                }}
                defaultZoom={11}
              >
                <Marker
                  lat={data.locaties.edges[0].node.googleMapsLocatie.lat}
                  lng={data.locaties.edges[0].node.googleMapsLocatie.lon}
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  {
    locaties: allContentfulLocaties(filter: { locatie: { eq: "Zeewolde" } }) {
      edges {
        node {
          locatie
          adres
          postcode
          plaats
          telefoonnummer
          email
          openingsDagen
          openingstijden
          googleMapsLocatie {
            lon
            lat
          }
          fotos {
            id
            fixed(width: 250, height: 250) {
              ...GatsbyContentfulFixed_tracedSVG
            }
          }
        }
      }
    }
  }
`

const Marker = () => (
  <div
    style={{
      width: '30px',
      height: '30px',
      borderRadius: '50% 50% 50% 0',
      background: '#33b5e5',
      position: 'absolute',
      transform: 'rotate(-45deg)',
      left: '50%',
      top: '50%',
      margin: '-20px 0 0 -20px',
    }}
  />
)

export default ZeewoldePage
